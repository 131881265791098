import { memo, ReactNode } from 'react';
import cn from 'classnames';

import { SectionTitle } from '../SectionTitle';
import { IWrapperProps } from '../SectionTitle/types';
import { BlockSectionTheme } from './types';

import s from './BlockSection.module.scss';

interface IBlockSectionComponent extends IWrapperProps {
    classname?: string;
    contentClassname?: string;
    titleClassname?: string;
    children: ReactNode;
    theme: BlockSectionTheme;
    title: string;
}

const BlockSectionComponent = (props: IBlockSectionComponent) => {
    const {
        children,
        theme,
        title,
        classname,
        contentClassname,
        titleClassname,
        rtlDirection,
    } = props;

    return (
        <div
            className={cn(classname, s.container, s[theme], {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <div className={cn(contentClassname, s.contentContainer)}>
                <SectionTitle
                    classname={cn(titleClassname, s.title)}
                    title={title}
                    theme={theme}
                    rtlDirection={rtlDirection}
                />
                {children}
            </div>
        </div>
    );
};

export const BlockSection = memo(BlockSectionComponent);
