import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as Logo } from '../../shared/assets/images/circle3.svg';
import { IResponseData } from '../../shared/types';
import { IWrapperProps } from '../../shared/ui/SectionTitle/types';

import s from './Result.module.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { CopyToClipboard } = require('react-copy-to-clipboard');

export interface IResultComponent extends IWrapperProps {
    classname?: string;
    data: IResponseData;
    handleClickBack: () => void;
}

const ResultComponent = (props: IResultComponent) => {
    const { classname, rtlDirection, data, handleClickBack } = props;

    const { t } = useTranslation();

    const url = window.URL.createObjectURL(new Blob([data.guarantee]));

    return (
        <div
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <div className={s.titleWrapper}>
                <Logo className={s.logo} />
                <h3 className={s.title}>{t('MIXTURA')}</h3>
            </div>
            <div className={s.textWrapper}>
                <span className={s.text}>{t('Send your bitcoin to:')}</span>
                <div className={s.addressWrapper}>
                    <span className={s.address}>{data.address}</span>
                    <CopyToClipboard text={data.address}>
                        <span className={s.copyButton}>
                            {t('Copy address')}
                        </span>
                    </CopyToClipboard>
                </div>
            </div>
            <div className={s.qrWrapper}>
                <QR data={data.qr} />
            </div>
            <div className={s.btnWrapper}>
                <a
                    className={s.dowloadLink}
                    href={url}
                    download={'guarantee.txt'}
                >
                    {t('Download the letter of guarantee')}
                </a>
                <button className={s.btn} onClick={handleClickBack}>
                    {t('Repeat')}
                </button>
            </div>
        </div>
    );
};

const QR = ({ data }: { data: string }) => (
    <img className={s.qr} src={`data:image/jpeg;base64,${data}`} />
);

export const Result = memo(ResultComponent);
