import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { IWrapperProps, SectionTitleTheme } from './types';

import s from './SectionTitle.module.scss';

interface ISectionTitleComponent extends IWrapperProps {
    classname?: string;
    title: string;
    theme: SectionTitleTheme;
}

const SectionTitleComponent = (props: ISectionTitleComponent) => {
    const { title, theme, classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <h2
            className={cn(s.title, s[theme], classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            {t(title)}
        </h2>
    );
};

export const SectionTitle = memo(SectionTitleComponent);
