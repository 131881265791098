import { FormEvent, memo, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Input } from 'shared/ui/Input';

import { IUseFormProps } from '../../app/hooks/useFormProps';

import { IWrapperProps } from '../../shared/ui/SectionTitle/types';
import { ReactComponent as CircleIcon } from './assets/circle.svg';
import { LangSwitcher } from './LangSwitcher/LangSwitcher';
import { Nav } from './Nav/Nav';

import s from './Hero.module.scss';

interface IHeroProps
    extends IWrapperProps,
        Omit<IUseFormProps, 'isSubmitted' | 'onHandleClearData'> {
    loaded: boolean;
    howItWorkRef: RefObject<HTMLInputElement>;
    benefitsRef: RefObject<HTMLDivElement>;
    faqsRef: RefObject<HTMLDivElement>;
    contactsRef: RefObject<HTMLDivElement>;
}

const HeroComponent = (props: IHeroProps) => {
    const {
        loaded,
        rtlDirection,
        howItWorkRef,
        benefitsRef,
        faqsRef,
        contactsRef,
        onHandleSubmit,
        firstAddress,
        secondAddress,
        isLoading,
        handleChangeFirstAddress,
        handleChangeSecondAddress,
        error,
    } = props;
    const { t } = useTranslation();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onHandleSubmit(firstAddress, secondAddress);
    };

    return (
        <section
            className={cn(s.section, {
                [s.loaded]: loaded,
                [s.rtlDirection]: rtlDirection,
                [s.firstRender]: !loaded,
            })}
        >
            <div className={s.header}>
                <LangSwitcher />
                <Nav
                    howItWorkRef={howItWorkRef}
                    benefitsRef={benefitsRef}
                    faqsRef={faqsRef}
                    contactsRef={contactsRef}
                    rtlDirection={rtlDirection}
                />
            </div>
            <div className={s.iconWrapper}>
                <CircleIcon
                    className={cn(s.circleIcon, { [s.played]: loaded })}
                />
                <div className={cn(s.logoBlock, { [s.played]: loaded })}>
                    <p className={s.logoTitle1}>
                        <span>{t('M')}</span>
                        <span>{t('I')}</span>
                        <span>{t('X')}</span>
                        <span>{t('T')}</span>
                        <span>{t('U')}</span>
                        <span>{t('R')}</span>
                        <span>{t('A')}</span>
                    </p>
                    <p className={s.logoTitle2}>
                        <span>{t('B')}</span>
                        <span>{t('I')}</span>
                        <span>{t('T')}</span>
                        <span>{t('C')}</span>
                        <span>{t('O')}</span>
                        <span>{t('I')}</span>
                        <span>{t('N')}</span> <span>{t('M')}</span>
                        <span>{t('I')}</span>
                        <span>{t('X')}</span>
                        <span>{t('E')}</span>
                        <span>{t('R')}</span> <span>{t('2')}</span>
                        <span>{t('.')}</span>
                        <span>{t('0')}</span>
                    </p>
                </div>
            </div>
            <div className={s.formWrapper}>
                <p className={s.formTitle}>
                    {t('Enter your Bitcoin forward to address below:')}
                </p>
                <form onSubmit={handleSubmit} className={s.form}>
                    <Input
                        onChange={handleChangeFirstAddress}
                        value={firstAddress}
                        placeholder='First address (optional)'
                        disabled={isLoading}
                    />
                    <Input
                        onChange={handleChangeSecondAddress}
                        value={secondAddress}
                        placeholder='Second address (optional)'
                        disabled={isLoading}
                    />
                    {error && <p className={s.error}>{t(error)}</p>}
                    {isLoading ? (
                        <CircleIcon className={cn(s.circleLoader)} />
                    ) : (
                        <button type={'submit'} className={s.btn}>
                            {t('MIX')}
                        </button>
                    )}
                </form>
            </div>
        </section>
    );
};

export const Hero = memo(HeroComponent);
