import { useEffect, useState } from 'react';
import cn from 'classnames';

import { MainPage } from '../pages/MainPage';
import { ResultPage } from '../pages/ResultPage';

import { useFormProps } from './hooks/useFormProps';

import './styles/index.scss';

function App() {
    const { isSubmitted, onHandleClearData, ...otherProps } = useFormProps();
    const [loaded, setLoaded] = useState(false);
    const [inited, setInited] = useState(false);

    useEffect(() => {
        if (!inited) {
            setTimeout(() => {
                setLoaded(true);
                setInited(true);
            }, 4000);
        }
    }, [inited]);

    return (
        <div className={cn('app', { loaded: loaded })}>
            {!isSubmitted && <MainPage {...otherProps} loaded={loaded} />}
            {isSubmitted && (
                <ResultPage
                    handleClickBack={onHandleClearData}
                    data={isSubmitted}
                />
            )}
        </div>
    );
}

export default App;
