import { useCallback, useState } from 'react';

import { api } from '../../shared/api/instance';
import { IResponseData } from '../../shared/types';

export interface IUseFormProps {
    onHandleSubmit: (forward_addr: string, forward_addr2: string) => void;
    onHandleClearData: () => void;
    isSubmitted: IResponseData | null;
    handleChangeFirstAddress: (e: string) => void;
    handleChangeSecondAddress: (e: string) => void;
    firstAddress: string;
    secondAddress: string;
    error: string;
    isLoading: boolean;
}

export const useFormProps = (): IUseFormProps => {
    const [isSubmitted, setIsSubmitted] = useState<IResponseData | null>(null);
    const [firstAddress, setFirstAddress] = useState('');
    const [secondAddress, setSecondAddress] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeFirstAddress = useCallback((e: string) => {
        setFirstAddress(() => e);
    }, []);

    const handleChangeSecondAddress = useCallback((e: string) => {
        setSecondAddress(() => e);
    }, []);

    const onHandleSubmit = async (
        forward_addr: string,
        forward_addr2: string,
    ) => {
        try {
            setIsLoading(true);
            setError(() => '');
            if (!forward_addr && !secondAddress) {
                setIsLoading(false);
                return setError(() => 'Address is required');
            }

            const { data } = await api.get('/token');

            const response = await api.post('/mix', {
                forward_addr,
                forward_addr2,
                token: data.token,
            });

            setIsSubmitted(response.data);
            setIsLoading(false);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            setError('Bad request');
            setIsLoading(false);
        }
    };

    const onHandleClearData = () => {
        setIsSubmitted(() => null);
        setSecondAddress(() => '');
        setFirstAddress(() => '');
    };

    return {
        onHandleSubmit,
        onHandleClearData,
        isSubmitted,
        handleChangeFirstAddress,
        handleChangeSecondAddress,
        firstAddress,
        secondAddress,
        error,
        isLoading,
    };
};
