export const BENEFITS_I18_MAP = [
    {
        question: 'The algorithm: Bitcoin Mixer 2.0',
        answer: 'The algorithm: Bitcoin Mixer 2.0 TEXT',
    },
    {
        question: 'No registration, no logs',
        answer: 'No registration, no logs TEXT',
    },
    {
        question: 'We are available 24x7x365',
        answer: 'We are available 24x7x365 TEXT',
    },
    {
        question: 'Letters of guarantee',
        answer: 'Letters of guarantee TEXT',
    },
];
