export const FAQS_I18_MAP = [
    {
        question: 'What is Bitcoin Mixer 2.0',
        answer: 'What is Bitcoin Mixer 2.0 TEXT',
    },
    {
        question: 'Can I trust you with my money',
        answer: 'Can I trust you with my money TEXT',
    },
    {
        question: 'How long does it take to cleanse Bitcoins',
        answer: 'How long does it take to cleanse Bitcoins TEXT',
    },
    {
        question: 'How many confirmations are required to accept a transfer',
        answer: 'How many confirmations are required to accept a transfer TEXT',
    },
    {
        question: 'What is a minimum amount of funds for cleansing',
        answer: 'What is a minimum amount of funds for cleansing TEXT',
    },
    {
        question: 'What is a maximum amount of funds for cleansing',
        answer: 'What is a maximum amount of funds for cleansing TEXT',
    },
    {
        question: 'What happens if I sent less money than required',
        answer: 'What happens if I sent less money than required TEXT',
    },
    {
        question: 'How much does it cost',
        answer: 'How much does it cost TEXT',
    },
    {
        question: 'What kind of logs is stored in the system',
        answer: 'What kind of logs is stored in the system TEXT',
    },
    {
        question: 'What is a letter of guarantee',
        answer: 'What is a letter of guarantee TEXT',
    },
    {
        question: 'How can I check a letter of guarantee',
        answer: 'How can I check a letter of guarantee TEXT',
    },
    {
        question: 'How long do addresses for coins transfer remain valid',
        answer: 'How long do addresses for coins transfer remain valid TEXT',
    },
    {
        question:
            'My browser had closed before I could get a confirmation for my transaction',
        answer: 'My browser had closed before I could get a confirmation for my transaction TEXT',
    },
];
