import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as Circle1 } from 'shared/assets/images/circle1.svg';
import { ReactComponent as Circle2 } from 'shared/assets/images/circle2.svg';
import { ReactComponent as Circle3 } from 'shared/assets/images/circle3.svg';
import { ReactComponent as Circle4 } from 'shared/assets/images/circle4.svg';
import { BlockSection } from 'shared/ui/BlockSection';

import { IWrapperProps } from '../../shared/ui/SectionTitle/types';
import { FAQS_I18_MAP } from './consts';

import s from './FAQS.module.scss';

interface IFAQSComponent extends IWrapperProps {
    classname?: string;
}

const FAQSComponent = (props: IFAQSComponent) => {
    const { classname, rtlDirection } = props;
    const { t } = useTranslation();

    return (
        <div
            className={cn(s.container, classname, {
                [s.rtlDirection]: rtlDirection,
            })}
        >
            <ol className={s.list}>
                {FAQS_I18_MAP.map(({ question, answer }, index) => (
                    <React.Fragment key={question}>
                        <li className={s.question}>
                            {index + 1}. {t(question)}
                        </li>
                        <p className={s.answer}>{t(answer)}</p>
                    </React.Fragment>
                ))}
            </ol>
            <div className={s.images}>
                <div className={s.image}>
                    <Circle4 />
                </div>
                <div className={s.image}>
                    <Circle3 />
                </div>
                <div className={s.image}>
                    <Circle2 />
                </div>
                <div className={s.image}>
                    <Circle1 />
                </div>
            </div>
        </div>
    );
};

const FAQSWithWrapper = ({ rtlDirection }: IWrapperProps) => {
    return (
        <BlockSection
            rtlDirection={rtlDirection}
            theme={'WHITE'}
            title={'FAQS'}
        >
            <FAQSComponent rtlDirection={rtlDirection} />
        </BlockSection>
    );
};

export const FAQS = memo(FAQSWithWrapper);
